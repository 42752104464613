.item {
	flex: 1 0 28vw;
	max-width: 28vw;
	margin-bottom: 10px;
	position: relative;
}

.item .product-image {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 500px;
	transition: all 0.5s ease;
	border-radius: 3px;
	overflow: hidden;
	opacity: 1;
}

.item .product-image.sold {
	opacity: 0.5;
}

.item .product-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center center;
	transition: all 0.5s ease;
	border-radius: 3px;
}

.item .product-image:hover img {
	transform: scale(0.97);
	cursor: pointer;
}

.item .product-info {
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin: 20px auto;
	width: 100%;
}

.item .product-info h3,
.item .product-info span {
	font-family: var(--semibold);
	font-size: 16px;
}

.item .sold-out {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	inset: 10px 0 0 10px;
	border: 1px solid var(--primary);
	width: 50px;
	height: 50px;
	border-radius: 50%;
	z-index: 5;
}

.item .sold-out span {
	font-family: var(--semibold);
	font-size: 16px;
	color: var(--primary);
	text-transform: uppercase;
	text-align: center;
}

@media screen and (max-width: 1400px) {
	.item {
		flex: 1 0 29.5vw;
		max-width: 29.5vw;
		margin-bottom: 10px;
	}
}

@media screen and (max-width: 1200px) {
	.item {
		flex: 1 0 29.5vw;
		max-width: 29.5vw;
		margin-bottom: 10px;
	}
}

@media screen and (max-width: 1024px) {
	.item {
		flex: 1 0 29.5vw;
		max-width: 29.5vw;
		margin-bottom: 10px;
	}
	.item .product-image {
		height: 400px;
	}
	.item .product-info h3,
	.item .product-info span {
		font-family: var(--semibold);
		font-size: 18px;
	}
}

@media screen and (max-width: 768px) {
	.item {
		flex: 1 0 44vw;
		max-width: 44vw;
		margin-bottom: 10px;
	}
	.item .product-image {
		height: 400px;
	}
}

@media screen and (max-width: 480px) {
	.item {
		flex: 1 0 90vw;
		max-width: 90vw;
		margin-bottom: 7px;
	}
	.item .product-image {
		height: 400px;
	}
}
