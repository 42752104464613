.home-screen {
	display: flex;
	position: relative;
	height: 100vh;
	justify-content: center;
	align-items: center;
	z-index: 1;
	width: 100vw;
}

.home-screen .main-image {
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: 1;
}

.home-screen .main {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	z-index: 2;
	background-color: var(--primary50);
	padding-top: 150px;
}

.home-screen .main .textcontainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 800px;
}

.home-screen .main .buttoncontainer{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 300px;
}

.home-screen .main .textcontainer .title {
	font-size: 68px;
	color: var(--light);
	text-transform: uppercase;
	letter-spacing: 3px;
	font-family: var(--semibold);
	margin: 10px 0 40px 0;
	text-shadow: 2px 2px 0px var(--primary);
	line-height: 70px;
	text-align: center;
}

.home-screen .main .textcontainer p {
	font-size: 18px;
	color: var(--light);
	letter-spacing: 3px;
	font-family: var(--semibold);
	margin: 40px 0;
	text-transform: uppercase;
	text-shadow: 2px 2px 0px var(--primary);
	text-align: center;
}

@media screen and (max-width: 1200px) {
	.home-screen .main .textcontainer {
		width: 800px;
	}
}

@media screen and (max-width: 1024px) {
	.home-screen .main .textcontainer {
		width: 70vw;
	}
}

@media screen and (max-width: 768px) {
	.home-screen {
		height: 100vh;
	}
	.home-screen .main .textcontainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 85vw;
	}

	.home-screen .main .textcontainer .title {
		font-size: 38px;
		margin: 10px 0 5px 0;
	}
	.home-screen .main .textcontainer p {
		font-size: 16px;
		margin: 20px 0;
		text-align: center;
	}
}

@media screen and (max-width: 480px) {
	.home-screen {
		height: 100vh;
		margin-top: 60px;
	}
	.home-screen .main .textcontainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 90vw;
	}
	.home-screen .main .textcontainer .title {
		font-size: 24px;
		margin: 5px 0;
		line-height: 40px;
	}
	.home-screen .main .textcontainer p {
		font-size: 14px;
		margin: 15px 0;
		text-align: center;
	}
}
