.cart-item .image-container {
	width: 100px;
	height: 100px;
	margin-right: 10px;
	border-radius: 3px;
	overflow: hidden;
}

.cart-item .image-container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.cart-item .item-details {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 280px;
}

.cart-item .item-details h3 {
	font-size: 14px;
	font-family: var(--semibold);
	color: var(--light);
	text-transform: uppercase;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 100%;
}

.cart-item .item-details p {
	font-size: 14px;
	font-family: var(--regular);
	color: var(--light);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 100%;
}

.cart-item .qty-price {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-top: 13px;
}

.cart-item .quantity {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100px;
}

.cart-item .quantity .quantity-btn {
	width: 60px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--gray);
	border-radius: 2px;
	cursor: pointer;
	transition: all 0.5s ease;
	color: var(--light);
	padding: 0 5px;
}

.cart-item .quantity .quantity-btn:hover {
	background-color: var(--secondary);
}

.cart-item .quantity p {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	font-family: var(--semibold);
	color: var(--light);
	text-transform: uppercase;
	margin: 0;
	text-align: center;
	height: 40px;
	border: 1px solid var(--gray);
}

.cart-item .qty-price h4 {
	font-size: 20px;
	font-family: var(--semibold);
	color: var(--light);
	text-transform: uppercase;
}
