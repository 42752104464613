.shop-screen {
	display: flex;
	position: relative;
	flex-direction: column;
	background-color: var(--background);
	height: fit-content;
	justify-content: center;
	align-items: center;
	padding-top: 100px;
	border-bottom: 2px solid var(--secondary);
}

.shop-screen .main {
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	width: 85vw;
	height: fit-content;
	overflow: hidden;
	margin: 50px 0;
}

.shop-screen .container {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 400px;
	background-color: var(--secondary);
}

.shop-screen .container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: all 0.5s ease;
}

.shop-screen .image-theme {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: var(--primary);
	opacity: 0.8;
	z-index: 1;
}

.shop-screen .container:hover img {
	-webkit-filter: blur(5px);
	filter: blur(5px);
}

.shop-screen .inner-container {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 40vw;
	z-index: 2;
}

.shop-screen .inner-container h4 {
	font-size: 50px;
	color: var(--background);
	font-family: var(--bold);
	text-transform: uppercase;
	text-align: center;
	z-index: 2;
}

.shop-screen .inner-container p{
	font-size: 16px;
	color: var(--background);
	font-family: var(--regular);
	text-align: center;
	z-index: 2;
}

.shop-screen .main .products {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	width: 100%;
}

@media screen and (max-width: 1490px) {
	.shop-screen .inner-container {
		width: 50vw;
	}
	.shop-screen .inner-container h4 {
		font-size: 45px;
	}
	.shop-screen .main {
		width: 90vw;
	}
}

@media screen and (max-width: 1200px) {
	.shop-screen .inner-container {
		width: 60vw;
	}
	.shop-screen .inner-container h4 {
		font-size: 40px;
	}
	.shop-screen .main {
		width: 90vw;
	}
}

@media screen and (max-width: 1024px) {
	.shop-screen .inner-container {
		width: 70vw;
	}
	.shop-screen .inner-container h4 {
		font-size: 30px;
	}
	.shop-screen .container {
		height: 300px;
	}

}

@media screen and (max-width: 768px) {
	.shop-screen .inner-container {
		width: 80vw;
	}
	.shop-screen .inner-container h4 {
		font-size: 25px;
	}
	.shop-screen .inner-container p{
		font-size: 14px;
	}
	.shop-screen .container {
		height: 250px;
	}
	.shop-screen .main {
		margin: 40px 0;
	}
}

@media screen and (max-width: 480px) {
	.shop-screen {
		padding-top: 60px;
	}
	.shop-screen .inner-container h4 {
		font-size: 20px;
	}
	.shop-screen .inner-container p{
		font-size: 14px;
	}
	.shop-screen .container {
		height: 200px;
	}
	.shop-screen .main {
		margin: 40px 0;
	}
}
