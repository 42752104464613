.navbar {
	top: 0;
	width: 100vw;
	height: 100px;
	z-index: 995;
	display: flex;
	position: fixed;
	align-items: center;
	justify-content: center;
	background-color: var(--primary50);
}
.navbar.scrolled {
	background-color: var(--primary);
	border-bottom: 2px solid var(--secondary);
}
.navbar .container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	width: 100%;
}
.navbar .nav-right {
	display: grid;
	justify-content: center;
	align-items: center;
	list-style: none;
	margin-right: 130px;
	grid-template-columns: repeat(5, auto);
}
.navbar .nav-right.small {
	display: none;
}
.navbar .container .logo {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 200px;
	height: 100px;
	overflow: hidden;
	margin-left: 130px;
}
.navbar .container .logo img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	transition: all 0.5s ease;
}
.navbar .container .logo img:hover {
	transform: scale(1.1);
	cursor: pointer;
}
.navbar .nav-right .cart {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid var(--light);
	padding: 3px;
	border-radius: 50px;
	cursor: pointer;
	margin-left: 50px;
}
.navbar .cart-icon-container {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background-color: var(--secondary);
	padding: 7px;
	margin-right: 10px;
}
.navbar .cart-number {
	font-size: 14px;
	color: var(--light);
	font-family: var(--semibold);
	border-left: 1px solid var(--light);
	padding: 0 15px;
}
.navbar .cart-icon {
	transition: all 0.5s ease;
	height: 25px;
	width: 25px;
	color: var(--light);
}
.navbar .cart:hover .cart-icon {
	transform: scale(1.1);
	cursor: pointer;
}
.navbar .logo-text:hover,
.navbar .nav-icon:hover {
	transform: scale(1.1);
	cursor: pointer;
	color: var(--light);
}
.navbar .nav-right .navlink {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto 10px;
	padding: 5px 10px;
	cursor: pointer;
	transition: all 0.5s ease;
	width: 150px;
}
.navbar .nav-right .navlink:hover {
	padding-bottom: 15px;
	border-bottom: 2px solid var(--secondary);
}
.navbar .nav-right .navlink .nav-icon {
	transition: all 0.5s ease;
	height: 25px;
	width: 25px;
	color: var(--light);
	margin-right: 10px;
}
.navbar .nav-right .navlink span {
	font-size: 14px;
	color: var(--light);
	font-family: var(--semibold);
	transition: all 0.5s ease;
}
.navbar .nav-right .navlink span.hide-desktop {
	display: none;
}

.navbar .menu-icon {
	display: none;
}

@media screen and (max-width: 1400px) {
	.navbar .container .logo {
		margin-left: 90px;
	}
	.navbar .nav-right {
		margin-right: 90px;
	}
}

@media screen and (max-width: 1200px) {
	.navbar .container .logo {
		margin-left: 30px;
	}
	.navbar .nav-right {
		margin-right: 70px;
	}
}

@media screen and (max-width: 1024px) {
	.navbar {
		height: 80px;
		background-color: var(--primary);
	}
	.navbar .container .logo {
		margin-left: 30px;
		width: 180px;
		height: 80px;
	}
	.navbar .nav-right {
		margin-right: 30px;
	}
	.navbar .menu-icon {
		top: 30px;
		right: 50px;
		display: block;
		cursor: pointer;
		position: absolute;
		height: 30px;
		width: 30px;
	}
	.navbar .nav-right {
		position: absolute;
		top: 80px;
		display: flex;
		flex-direction: column;
		justify-items: flex-start;
		align-items: center;
		width: 100vw;
		height: 100vh;
		left: -100vw;
		opacity: 0;
		transition: all 0.5s ease;
		background-color: var(--primary);
	}
	.navbar .nav-right.active {
		left: 0;
		opacity: 1;
		z-index: 1;
	}
	.navbar .nav-right .navlink {
		display: flex;
		margin: 10px;
		padding: 30px 10px;
		cursor: pointer;
		transition: all 0.5s ease;
		width: 100%;
	}
	.navbar .nav-right .navlink:hover {
		padding-bottom: 30px;
	}
	.navbar .nav-right .navlink span.hide-desktop {
		display: flex;
	}
	.navbar .cart {
		position: absolute;
		top: 30px;
		right: 50px;
	}
}

@media screen and (max-width: 480px) {
	.navbar {
		height: 60px;
	}
	.navbar .container .logo {
		margin-left: 10px;
		width: 120px;
		height: 60px;
	}
	.navbar .menu-icon {
		top: 20px;
		right: 30px;
		height: 25px;
		width: 25px;
		color: var(--light);
	}
	.navbar .nav-right {
		top: 60px;
	}
}
