.login {
	background-color: var(--light);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: var(--regular);
	height: 70vh;
	margin-top: 100px;
}

.login .small-screen-login {
	display: none;
}

.login .login-heading {
	font-family: var(--bold);
	margin: 0;
	color: var(--primary);
	margin-bottom: 10px;
}

.login .login-text {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
	color: var(--secondary);
}

.login .small-text {
	font-size: 14px;
	color: var(--secondary);
}

.login .login-link {
	color: var(--secondary);
	font-size: 14px;
	text-decoration: none !important;
	margin: 15px 0;
}

.login form {
	background-color: var(--light);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}

.login .container {
	background-color: var(--light);
	border-radius: 10px;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	position: relative;
	overflow: hidden;
	width: 1000px;
	min-height: 480px;
}

.login .form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.login .sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
}

.login .container.right-panel-active .sign-in-container {
	transform: translateX(100%);
}

.login .sign-up-container {
	left: 0;
	width: 50%;
	opacity: 0;
	z-index: 1;
}

.login .container.right-panel-active .sign-up-container {
	transform: translateX(100%);
	opacity: 1;
	z-index: 5;
	animation: show 0.6s;
}

@keyframes show {
	0%,
	49.99% {
		opacity: 0;
		z-index: 1;
	}

	50%,
	100% {
		opacity: 1;
		z-index: 5;
	}
}

.login .overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: transform 0.6s ease-in-out;
	z-index: 100;
}

.login .container.right-panel-active .overlay-container {
	transform: translateX(-100%);
}

.login .overlay {
	background: var(--primary);
	background: -webkit-linear-gradient(
		to right,
		var(--background),
		var(--primary)
	);
	background: linear-gradient(to right, var(--light), var(--primary));
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: var(--secondary);
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.login .container.right-panel-active .overlay {
	transform: translateX(50%);
}

.login .overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.login .overlay-left {
	transform: translateX(-20%);
}

.login .container.right-panel-active .overlay-left {
	transform: translateX(0);
}

.login .overlay-right {
	right: 0;
	transform: translateX(0);
}

.login .container.right-panel-active .overlay-right {
	transform: translateX(20%);
}

.login .white-text {
	color: var(--light) !important;
}

@media screen and (max-width: 1200px) {
	.login .container {
		width: 85vw;
	}
}

@media screen and (max-width: 1024px) {
	.login .container {
		width: 90vw;
	}
}

@media screen and (max-width: 768px) {
	.login .container {
		display: none;
	}
	.login .small-screen-login {
		display: flex;
		background-color: var(--light);
		border-radius: 10px;
		box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
		position: relative;
		overflow: hidden;
		width: 90vw;
		min-height: 480px;
		height: fit-content;
		padding: 20px auto;
	}
	.login form {
		width: 100%;
		padding: 0 25px;
	}
	.login .login-link {
		cursor: pointer;
		color: var(--primary);
		transition: all 0.5s ease;
	}
	.login .login-link:hover {
		color: var(--tertiary);
	}
}

@media screen and (max-width: 480px) {
	.login{
		margin-top: 0px;
		padding-top: 50px;
	}
	.login .container {
		display: none;
	}
	.login .small-screen-login {
		display: flex;
		background-color: var(--light);
		border-radius: 10px;
		box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
		position: relative;
		overflow: hidden;
		width: 90vw;
		height: fit-content;
		padding: 20px auto;
	}
	.login form {
		width: 100%;
		padding: 0 15px;
	}
	.login .login-link {
		cursor: pointer;
		color: var(--primary);
		transition: all 0.5s ease;
	}
	.login .login-link:hover {
		color: var(--tertiary);
	}
}
