.empty {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30vh;
	width: 100%;
}

.empty h1 {
	font-size: 30px;
	color: var(--light);
	font-family: var(--semibold);
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 480px) {
}
