@font-face {
	font-family: 'Poppins-Regular';
	src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Poppins-Medium';
	src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Poppins-SemiBold';
	src: url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Poppins-Bold';
	src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
	font-display: swap;
}


.theme {
	/* Main colors */
	--primary: #28333f;
	--secondary: #4b7bb6;
	--tertiary: #E8424B;
	--light: #fffdfe;
	--gray: #8b9496;
	--primary50: #28333f95;

	/* Other colors */
	--warning: #f0ad4e;
	--danger: #d9534f;
	--success: #5cb85c;

	/* Fonts */
	--regular: 'Poppins-Regular', sans-serif;
	--medium: 'Poppins-Medium', sans-serif;
	--semibold: 'Poppins-SemiBold', sans-serif;
	--bold: 'Poppins-Bold', sans-serif;
}

.tenpixels {
	padding: 5px;
}

body {
	font-family: var(--regular);
	font-size: 14px;
	color: var(--light);
	overflow-x: hidden;
	background-color: var(--primary);
	transition: transform 0.6s cubic-bezier(0.95, 0.05, 0.795, 0.035);
}

#root {
	max-width: 100%;
	overflow-x: hidden;
}

.cart-open {
	transform: translateX(-450px);
	overflow: hidden;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	text-decoration: none;
}

html {
	scroll-behavior: smooth;
}

.link {
	text-decoration: none !important;
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: var(--light) !important;
}

::-webkit-scrollbar-thumb {
	background: var(--secondary) !important;
}

::-webkit-scrollbar-thumb:hover {
	background: var(--primary) !important;
}

@media screen and (max-width: 480px) {
	.cart-open {
		transform: translateX(-110vw);
		overflow: hidden;
	}
}