.dashboard-screen {
	display: flex;
	position: relative;
	flex-direction: column;
	background-color: var(--light);
	height: fit-content;
	justify-content: center;
	align-items: center;
	margin-top: 100px;
}

.dashboard-screen .main {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 85vw;
	min-height: 50vh;
	height: fit-content;
	overflow: hidden;
	margin: 50px 0 50px 0;
	border: 2px solid var(--secondary);
	border-radius: 5px;
}

.dashboard-screen .initials {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 150px;
	height: 150px;
	border-radius: 75px;
	border: 3px solid var(--primary);
	background-color: var(--secondary);
	margin-top: 20px;
}

.dashboard-screen .initials p {
	font-size: 75px;
	font-family: var(--semibold);
	color: var(--light);
}

.dashboard-screen .name {
	font-size: 45px;
	font-family: var(--semibold);
	color: var(--primary);
	letter-spacing: 1px;
	margin-top: 20px;
}

.dashboard-screen .email {
	font-size: 16px;
	font-family: var(--regular);
	color: var(--secondary);
	margin-bottom: 20px;
}

.dashboard-screen .logout {
	position: absolute;
	top: 20px;
	right: 20px;
	font-size: 18px;
	font-family: var(--semibold);
	color: var(--primary);
	cursor: pointer;
	transition: all 0.5s ease;
}

.dashboard-screen .logout:hover {
	color: var(--secondary);
}

.dashboard-screen .admin {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 85vw;
	height: fit-content;
	overflow: hidden;
	margin: 5px 0;
	border: 2px solid var(--secondary);
	border-radius: 5px;
	padding: 20px;
}

.dashboard-screen .messages {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 85vw;
	height: fit-content;
	overflow: hidden;
	margin: 5px 0;
	border: 2px solid var(--secondary);
	border-radius: 5px;
	padding: 20px;
}

.dashboard-screen .admin .overview {
	display: flex;
	position: relative;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	height: fit-content;
	overflow: hidden;
	padding: 0 10px;
	width: 100%;
}

.dashboard-screen .admin .title {
	font-size: 25px;
	font-family: var(--bold);
	text-transform: uppercase;
	color: var(--primary);
	margin: 20px 0;
	letter-spacing: 1px;
}

.dashboard-screen .admin .numbers {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: fit-content;
	overflow: hidden;
	margin-bottom: 50px;
}

.dashboard-screen .admin .numbers p {
	font-size: 18px;
	font-family: var(--semibold);
	color: var(--secondary);
	margin: 10px 0;
}

.dashboard-screen .admin .numbers h3 {
	font-size: 45px;
	font-family: var(--semibold);
	color: var(--primary);
}

.dashboard-screen .admin .add-product {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	height: fit-content;
	overflow: hidden;
	padding: 0 10px;
	width: 100%;
	transition: all 0.5s ease;
}

.dashboard-screen .admin .add-product.hidden {
	transform: scaleY(0);
	height: 0;
}

.dashboard-screen .add-product .image-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 440px;
	height: 435px;
	overflow: hidden;
	border-radius: 5px;
}

.dashboard-screen .add-product .image-container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.dashboard-screen .add-form {
	display: flex;
	position: relative;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	height: fit-content;
}

.dashboard-screen .add-form .upload,
.dashboard-screen .add-form .more_input {
	width: 100%;
	color: var(--primary);
	display: grid;
	border-radius: 8px;
	padding: 0 10px 10px 10px;
	margin-bottom: 10px;
}

.dashboard-screen .add-form .upload_body {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 10px;
	border: 2px dashed var(--primary);
	border-radius: inherit;
}

.dashboard-screen .add-form .upload .label {
	font-family: var(--semibold);
	font-size: 14px;
	color: var(--secondary);
	margin-left: 5px;
	margin-top: 10px;
	text-align: left;
}

.dashboard-screen .add-form .upload_body .upload_icon {
	margin-top: 50px;
	font-size: 48px;
}

.dashboard-screen .add-form .upload_body p {
	font-family: var(--regular);
	font-size: 14px;
	color: var(--secondary);
	margin-top: 10px;
	text-align: center;
	margin-bottom: 50px;
}

.dashboard-screen .add-form .upload_footer {
	position: relative;
	background-color: var(--tertiary);
	border-radius: inherit;
	transition: background-color 0.5s;
	margin-top: 10px;
}

.dashboard-screen .add-form .upload_button {
	display: block;
	text-align: center;
	padding: 12px;
	cursor: pointer;
	color: var(--primary);
}

.dashboard-screen .add-form .upload_footer:hover {
	background-color: var(--gray);
}

.dashboard-screen .add-form .upload_button span {
	opacity: 0.5;
}

.dashboard-screen .add-form #upload_input {
	position: absolute;
	inset: 0;
	opacity: 0;
	visibility: hidden;
}

.dashboard-screen .products {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	height: fit-content;
	width: 100%;
	overflow: hidden;
	border: 2px solid var(--gray);
	border-radius: 5px;
	padding-bottom: 10px;
}

.dashboard-screen .products .products-header {
	display: flex;
	position: relative;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: fit-content;
	overflow: hidden;
	padding: 0 10px;
	border-bottom: 2px solid var(--gray);
	background-color: var(--secondary);
}

.dashboard-screen .products-header .product {
	font-size: 18px;
	font-family: var(--semibold);
	color: var(--background);
	margin: 20px 0;
	letter-spacing: 1px;
	width: 40%;
}

.dashboard-screen .products-header .product-price,
.dashboard-screen .products-header .product-quantity,
.dashboard-screen .products-header .product-action {
	font-size: 18px;
	font-family: var(--semibold);
	color: var(--background);
	margin: 20px 0;
	letter-spacing: 1px;
	width: 20%;
}

.dashboard-screen .products-body {
	display: flex;
	position: relative;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: fit-content;
	overflow: hidden;
	padding: 0 10px;
	border-bottom: 2px solid var(--gray);
}

.dashboard-screen .products-body .product {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 20px 0;
	width: 30%;
}

.dashboard-screen .products-body .product h2 {
	font-size: 20px;
	font-family: var(--semibold);
	color: var(--primary);
	letter-spacing: 1px;
}

.dashboard-screen .products-body .product p {
	font-size: 14px;
	font-family: var(--semibold);
	color: var(--primary);
	letter-spacing: 1px;
}

.dashboard-screen .products-body .product p span {
	font-size: 14px;
	font-family: var(--regular);
	color: var(--primary);
	letter-spacing: 1px;
}

.dashboard-screen .products-body .image-link {
	width: 10%;
	font-size: 14px;
	font-family: var(--regular);
	color: var(--primary);
	letter-spacing: 1px;
	transition: all 0.5s ease;
	cursor: pointer;
}

.dashboard-screen .products-body .image-link:hover {
	color: var(--secondary);
	font-weight: 600;
}

.dashboard-screen .products-body .product-price,
.dashboard-screen .products-body .product-quantity,
.dashboard-screen .products-body .product-action {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	font-size: 14px;
	font-family: var(--regular);
	color: var(--primary);
	letter-spacing: 1px;
	width: 20%;
}

.dashboard-screen .products-body .product-action p {
	font-size: 18px;
	font-family: var(--semibold);
	color: var(--primary);
	letter-spacing: 1px;
	cursor: pointer;
	transition: all 0.5s ease-out;
	padding: 10px 0;
	border: 2px solid var(--background);
	width: 100px;
	text-align: center;
	border-radius: 5px;
}

.dashboard-screen .products-body .product-action p:hover {
	color: var(--primary);
	border: 2px solid var(--secondary);
	background-color: var(--tertiary);
}

.dashboard-screen .empty-list {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 10px;
}

.dashboard-screen .empty-list p {
	font-size: 18px;
	font-family: var(--semibold);
	color: var(--primary);
	letter-spacing: 1px;
	margin: 10px 0;
}

.dashboard-screen .clickable-title {
	cursor: pointer;
	transition: all 0.5s ease;
}

.dashboard-screen .clickable-title:hover {
	color: var(--secondary);
	transform: scale(1.05);
}

@media screen and (max-width: 1200px) {
	.dashboard-screen .main {
		width: 90vw;
	}
	.dashboard-screen .admin {
		width: 90vw;
	}
}

@media screen and (max-width: 768px) {
	.dashboard-screen .main {
		width: 90vw;
		margin: 50px 0;
	}
	.dashboard-screen .initials {
		width: 80px;
		height: 80px;
		margin-top: 30px;
	}
	.dashboard-screen .initials p {
		font-size: 30px;
	}
	.dashboard-screen .name {
		font-size: 20px;
		margin: 30px 0 15px 0;
	}
	.dashboard-screen .email {
		font-size: 14px;
		margin-bottom: 30px;
	}

	.dashboard-screen .admin {
		display: none;
	}
}

@media screen and (max-width: 480px) {
	.dashboard-screen .main {
		width: 90vw;
		margin: 50px 0;
	}
	.dashboard-screen .initials {
		width: 70px;
		height: 70px;
		margin-top: 30px;
	}
	.dashboard-screen .initials p {
		font-size: 25px;
	}
	.dashboard-screen .name {
		font-size: 20px;
		margin: 30px 0 15px 0;
	}
	.dashboard-screen .email {
		font-size: 14px;
		margin-bottom: 30px;
	}
	.dashboard-screen .admin {
		display: none;
	}
}
