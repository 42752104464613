.contact-screen {
	display: flex;
	position: relative;
	flex-direction: column;
	background-color: var(--light);
	height: fit-content;
	justify-content: center;
	align-items: center;
	border-bottom: 2px solid var(--secondary);
}

.contact-screen .main {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	width: 85vw;
	height: fit-content;
	overflow: hidden;
}

.contact-screen .container {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 500px;
	background-color: var(--secondary);
}

.contact-screen .container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: all 0.5s ease;
}

.contact-screen .image-theme {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: var(--primary);
	opacity: 0.8;
	z-index: 1;
}

.contact-screen .container:hover img {
	-webkit-filter: blur(5px);
	filter: blur(5px);
}

.contact-screen .inner-container {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 40vw;
	z-index: 2;
}

.contact-screen .inner-container h4 {
	font-size: 50px;
	color: var(--light);
	font-family: var(--bold);
	text-transform: uppercase;
	text-align: center;
	z-index: 2;
}

.contact-screen .main .info {
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1000px;
	border: 2px solid var(--secondary);
    border-radius: 10px;
    padding: 50px 100px;
    margin: 50px 0;
}

.contact-screen .main .info .imagecontainer{
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 10px;
    border: 2px solid var(--secondary);
}

.contact-screen .main .info h6	{
	font-size: 16px;
	color: var(--primary);
	font-family: var(--regular);
	text-align: center;
	margin: 20px 55px;
}

.contact-screen .main .info .imagecontainer img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.contact-screen .main .info .flex{
    display: flex;
	flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    width: 100%;
}

.contact-screen .main .info .btncontainer{
	position: relative;
	width: 500px;
	height: 40px;;
}


@media screen and (max-width: 1490px) {
	.contact-screen .inner-container {
		width: 50vw;
	}
	.contact-screen .inner-container h4 {
		font-size: 45px;
	}
	.contact-screen .main {
		width: 90vw;
	}
}

@media screen and (max-width: 1200px) {
	.contact-screen .inner-container {
		width: 60vw;
	}
	.contact-screen .inner-container h4 {
		font-size: 40px;
	}
	.contact-screen .main {
		width: 90vw;
	}
}

@media screen and (max-width: 1024px) {
	.contact-screen .inner-container h4 {
		font-size: 30px;
	}
	.contact-screen .container {
		height: 300px;
	}
	.contact-screen .main .info h6	{
		font-size: 16px;
		margin: 20px 30px;
	}
	.contact-screen .main .info {
		width: 90vw;
		border: 2px solid var(--secondary);
		border-radius: 10px;
		padding: 20px 50px;
		margin: 25px 0;
	}
	.contact-screen .main .info .btncontainer{
		position: relative;
		width: 80vw;
		height: 40px;
		margin-bottom: 30px;
	}
	
}

@media screen and (max-width: 768px) {
	.contact-screen .inner-container h4 {
		font-size: 25px;
	}
	.contact-screen .container {
		height: 250px;
	}
	.contact-screen .main .info h6	{
		font-size: 14px;
	}
	.contact-screen .main {
		margin: 40px 0;
	}
	.contact-screen .main .info .flex{
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}
	.contact-screen .main .info {
		width: 90vw;
		border: 2px solid var(--secondary);
		border-radius: 10px;
		padding: 10px 20px;
		margin: 25px 0;
	}
	.contact-screen .main .info .btncontainer{
		position: relative;
		width: 85vw;
		height: 40px;
		margin-bottom: 30px;
	}
}

@media screen and (max-width: 480px) {
	.contact-screen {
		padding-top: 60px;
	}
	.contact-screen .inner-container h4 {
		font-size: 20px;
	}
	.contact-screen .main .info h6	{
		font-size: 14px;
	}
	.contact-screen .container {
		height: 200px;
	}
	.contact-screen .main {
		margin: 40px 0;
	}
	.contact-screen .main .info .btncontainer{
		position: relative;
		width: 80vw;
		height: 40px;
		margin-bottom: 30px;
	}
}
