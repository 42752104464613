.photo-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--primary50);
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;
}

.photo-overlay .container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.photo-overlay .container .photo-wrapper {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 700px;
	height: 700px;
	border: 2px solid var(--secondary);
	border-radius: 5px;
}

.photo-overlay .container .photo-wrapper img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: 1;
}

.photo-overlay .container .close-btn {
	position: absolute;
	top: 10px;
	right: 10px;
	width: 40px;
	height: 40px;
	background-color: var(--primary50);
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	z-index: 2;
}

.photo-overlay .container .close-btn .icon {
	color: var(--light);
	font-size: 35px;
}

.photo-overlay .load-wrapper {
	position: absolute;
	top: 45%;
	left: 45%;
	width: 100px;
	height: 100px;
}

.photo-overlay .loader {
	width: 70px;
	height: 70px;
	border: 10px solid var(--light);
	border-top-color: var(--secondary);
	border-bottom-color: var(--primary);
	border-radius: 50%;
	animation: rotate 5s linear infinite;
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 480px) {
}
