.loader-container {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--primary50);
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loader-wrapper {
	width: 100px;
	height: 100px;
}

.loader {
	width: 100%;
	height: 100%;
	border: 10px solid var(--light);
	border-top-color: var(--secondary);
	border-bottom-color: var(--primary);
	border-radius: 50%;
	animation: rotate 5s linear infinite;
}

.loader-container .logo {
	object-fit: cover;
	width: 60%;
	height: 60%;
}

@keyframes rotate {
	0% {
		transform: scale(1) rotate(360deg);
	}
	50% {
		transform: scale(0.8) rotate(-360deg);
	}
	100% {
		transform: scale(1) rotate(360deg);
	}
}

@media screen and (max-width: 1024px) {
	.loader-wrapper {
		width: 100px;
		height: 100px;
	}
}

@media screen and (max-width: 768px) {
	.loader-wrapper {
		width: 90px;
		height: 90px;
	}
}

@media screen and (max-width: 480px) {
	.loader-wrapper {
		width: 90px;
		height: 90px;
	}
}
