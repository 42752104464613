.collection-screen {
	display: flex;
	position: relative;
	background-color: var(--primary);
	height: fit-content;
	justify-content: center;
	align-items: center;
	z-index: 1;
	padding-top: 100px;
	padding-bottom: 100px;
}

.collection-screen .container {
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	width: 85vw;
	height: 600px;
	overflow: hidden;
}

.collection-screen .container .left,
.collection-screen .container .right {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 28vw;
	height: 100%;
	overflow: hidden;
	border-radius: 2px;
}

.collection-screen .container .middle {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	width: 27vw;
	height: 100%;
	overflow: hidden;
	border-radius: 2px;
}

.collection-screen .container .middle .overlay {
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.3);
	cursor: pointer;
	transition: all 0.5s ease;
}

.collection-screen .container .middle .overlay:hover {
	background-color: rgba(0, 0, 0, 0.5);
}

.collection-screen .side-images {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 700px;
}

.collection-screen .middle-image {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 400px;
}

.collection-screen .side-images img,
.collection-screen .middle-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: top center;
	transition: all 0.5s ease;
}

.collection-screen .collection-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.collection-screen .collection-text h2 {
	font-size: 55px;
	font-family: var(--semibold);
	color: var(--text);
	letter-spacing: 3px;
	text-transform: uppercase;
	text-align: center;
}

.collection-screen .container .middle .overlay p {
	font-size: 30px;
	font-family: var(--semibold);
	color: var(--light);
	text-transform: uppercase;
	letter-spacing: 3px;
}


@media screen and (max-width: 1200px) {
	.collection-screen .container {
		width: 90vw;
		height: 600px;
	}
	.collection-screen .container .middle,
	.collection-screen .container .left,
	.collection-screen .container .right {
		width: 29vw;
	}
}

@media screen and (max-width: 1024px) {
	.collection-screen .container {
		width: 90vw;
		height: fit-content;
	}
	.collection-screen .container .left,
	.collection-screen .container .right {
		width: 40vw;
	}
	.collection-screen .container .middle {
		width: 85vw;
		border-left: 5px solid var(--primary);
		border-right: 5px solid var(--primary);
	}
	.collection-screen .collection-text h2 {
		font-size: 45px;
	}
	.collection-screen .container .middle .overlay p {
		font-size: 25px;
	}
}

@media screen and (max-width: 768px) {
	.collection-screen {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.collection-screen .container {
		width: 90vw;
		height: fit-content;
	}
	.collection-screen .container .left,
	.collection-screen .container .right {
		display: none;
	}
	.collection-screen .container .middle {
		width: 90vw;
	}
	.collection-screen .collection-text {
		width: 90vw;
		flex-direction: row;
		margin-bottom: 20px;
	}
	.collection-screen .collection-text h2 {
		font-size: 35px;
		margin: 0 10px;
	}
	.collection-screen .container .middle .overlay p {
		font-size: 20px;
	}
}

@media screen and (max-width: 480px) {
	.collection-screen {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.collection-screen .container {
		width: 90vw;
		height: fit-content;
	}
	.collection-screen .container .left,
	.collection-screen .container .right {
		display: none;
	}
	.collection-screen .container .middle {
		width: 90vw;
	}
	.collection-screen .collection-text {
		width: 90vw;
		flex-direction: row;
		margin-bottom: 20px;
	}
	.collection-screen .collection-text h2 {
		font-size: 25px;
		margin: 0 5px;
	}
	.collection-screen .container .middle .overlay p {
		font-size: 16px;
	}
}
