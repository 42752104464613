.buttonComponent {
	width: 100%;
	background-color: var(--secondary);
	border: 1px solid var(--primary);
	border-radius: 10px;
	padding: 5px;
	justify-content: center;
	align-items: center;
	margin-top: 10px;
	cursor: pointer;
	transition: all 0.5s ease;
}

.buttonComponent:hover {
	transform: scale(1.05);
}

.buttonComponent .text {
	font-family: var(--semibold);
	color: var(--light);
	font-size: 14px !important;
	padding: 7px 10px;
	text-align: center;
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 480px) {
}
