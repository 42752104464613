.about-screen {
	display: flex;
	position: relative;
	flex-direction: column;
	background-color: var(--background);
	height: fit-content;
	justify-content: center;
	align-items: center;
	padding-top: 100px;
	border-bottom: 2px solid var(--secondary);
}

.about-screen .main {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	width: 85vw;
	height: fit-content;
	overflow: hidden;
	margin-top: 50px;
}

.about-screen .container {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 400px;
	background-color: var(--secondary);
}

.about-screen .container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: all 0.5s ease;
}

.about-screen .image-theme {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: var(--primary);
	opacity: 0.8;
	z-index: 1;
}

.about-screen .container:hover img {
	-webkit-filter: blur(5px);
	filter: blur(5px);
}

.about-screen .inner-container {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 40vw;
	z-index: 2;
}

.about-screen .inner-container h4 {
	font-size: 50px;
	color: var(--background);
	font-family: var(--bold);
	text-transform: uppercase;
	text-align: center;
	z-index: 2;
}

.about-screen .inner-container p{
	font-size: 16px;
	color: var(--background);
	font-family: var(--regular);
	text-align: center;
	z-index: 2;
}

.about-screen .main .info {
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 800px;
}

.about-screen .main .info h4 {
    font-size: 50px;
    color: var(--light);
    font-family: var(--bold);
    text-transform: uppercase;
    text-align: center;
    margin: 10px 0;
    text-shadow: 2px 2px 0px var(--tertiary);
}

.about-screen .main .info .imagecontainer{
    width: 400px;
    height: 400px;
    overflow: hidden;
    border-radius: 10px;
    border: 2px solid var(--secondary);
}

.about-screen .main .info .imagecontainer img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about-screen .main .info h5 {
    font-size: 30px;
    color: var(--light);
    font-family: var(--bold);
    text-transform: uppercase;
    text-align: center;
    margin: 10px 0;
    text-shadow: 2px 2px 0px var(--tertiary);
}

.about-screen .main .info p {
    font-size: 16px;
    color: var(--light);
    font-family: var(--regular);
    text-align: center;
    margin: 20px 0;
}

.about-screen .values{
	display: flex;
	flex-wrap: wrap;
    justify-content: center;
    align-items: center;
	gap: 5px;
	width: 70vw;
    margin-bottom: 50px;
}

.about-screen .values .value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25vw;
    height: 200px;
    background-color: var(--secondary);
    border: 2px solid var(--primary);
    border-radius: 10px;
    transition: all 0.5s ease;
    padding: 10px 20px;
}

.about-screen .values .value:hover {
    transform: scale(1.01);
    border: 2px solid var(--tertiary);
}


.about-screen .values .value h5{
    font-size: 25px;
    color: var(--light);
    font-family: var(--bold);
    text-transform: uppercase;
    text-align: center;
    margin: 10px 0;
    text-shadow: 2px 2px 0px var(--tertiary);
}

.about-screen .values .value p{
    font-size: 16px;
    color: var(--light);
    font-family: var(--regular);
    text-align: center;
    margin: 10px 0;
}

@media screen and (max-width: 1490px) {
	.about-screen .inner-container {
		width: 90vw;
	}
	.about-screen .inner-container h4 {
		font-size: 45px;
	}
	.about-screen .main .info h4 {
		font-size: 45px;
	}
	.about-screen .main {
		width: 90vw;
	}
	.about-screen .values{
		gap: 5px;
		width: 70vw;
	}
	.about-screen .values .value {
		width: 30vw;
		height: 300px;
	}
}

@media screen and (max-width: 1200px) {
	.about-screen .inner-container {
		width: 90vw;
	}
	.about-screen .inner-container h4 {
		font-size: 40px;
	}
	.about-screen .main .info h4 {
		font-size: 40px;
	}
	.about-screen .main {
		width: 90vw;
	}
	.about-screen .main .info {
		width: 100%;
	}
	.about-screen .values{
		gap: 5px;
		width: 80vw;
	}
	.about-screen .values .value {
		width: 35vw;
		height: 300px;
	}
}

@media screen and (max-width: 1024px) {
	.about-screen .inner-container h4 {
		font-size: 30px;
	}
	.about-screen .main .info h4 {
		font-size: 30px;
	}
	.about-screen .main .info h5 {
		font-size: 25px;
	}
	.about-screen .container {
		height: 300px;
	}
	.about-screen .main .info {
		width: 100%;
	}
	.about-screen .values{
		gap: 5px;
		width: 85vw;
	}
	.about-screen .values .value {
		width: 40vw;
		height: 300px;
	}
	
}

@media screen and (max-width: 768px) {
	.about-screen .inner-container h4 {
		font-size: 25px;
	}
	.about-screen .main .info h4 {
		font-size: 25px;
	}
	.about-screen .main .info h5 {
		font-size: 20px;
	}
	.about-screen .container {
		height: 250px;
	}
	.about-screen .main {
		margin: 40px 0;
	}
	.about-screen .main .info p {
		font-size: 14px;
	}
	.about-screen .main .info {
		width: 100%;
	}
	.about-screen .values{
		gap: 5px;
		width: 85vw;
	}
	.about-screen .values .value {
		width: 85vw;
		height: 200px;
	}
	.about-screen .values .value h5{
		font-size: 20px;
	}
	.about-screen .values .value p{
		font-size: 14px;
	}
	.about-screen .main .info .imagecontainer{
		width: 85vw;
		height: 400px;
	}
}

@media screen and (max-width: 480px) {
	.about-screen {
		padding-top: 60px;
	}
	.about-screen .inner-container h4 {
		font-size: 20px;
	}
	.about-screen .main .info h4 {
		font-size: 20px;
	}
	.about-screen .main .info h5 {
		font-size: 18px;
	}
	.about-screen .container {
		height: 200px;
	}
	.about-screen .main {
		margin: 40px 0;
	}
	.about-screen .main .info p {
		font-size: 14px;
	}
	.about-screen .main .info {
		width: 100%;
	}
	.about-screen .values{
		gap: 5px;
		width: 85vw;
	}
	.about-screen .values .value {
		width: 85vw;
		height: 200px;
	}
	.about-screen .values .value h5{
		font-size: 18px;
	}
	.about-screen .values .value p{
		font-size: 14px;
	}
	.about-screen .main .info .imagecontainer{
		width: 85vw;
		height: 400px;
	}
}
