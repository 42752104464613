.password-container {
	background: var(--light);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-family: var(--regular);
	height: 70vh;
	margin-top: 100px;
}

.password-container .small-screen-login {
	display: none;
}

.password-container .password-heading {
	font-family: var(--bold);
	margin: 0;
	color: var(--secondary);
	margin-bottom: 10px;
}

.password-container .password-text {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
}

.password-container .small-text {
	font-size: 14px;
	color: var(--secondary);
}

.password-container form {
	background-color: var(--light);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 50px;
	height: 100%;
	text-align: center;
}

.password-container .container {
	background-color: var(--light);
	border-radius: 10px;
	box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	position: relative;
	overflow: hidden;
	width: 1000px;
	max-width: 100%;
	min-height: 480px;
}

.password-container .form-container {
	position: absolute;
	top: 0;
	height: 100%;
	transition: all 0.6s ease-in-out;
}

.password-container .sign-in-container {
	left: 0;
	width: 50%;
	z-index: 2;
}

.password-container .container.right-panel-active .sign-in-container {
	transform: translateX(100%);
}

.password-container .sign-up-container {
	left: 0;
	width: 50%;
	opacity: 1;
	z-index: 1;
}

.password-container .overlay-container {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;
	height: 100%;
	overflow: hidden;
	transition: transform 0.6s ease-in-out;
	z-index: 100;
}

.password-container .container.right-panel-active .overlay-container {
	transform: translateX(-100%);
}

.password-container .overlay {
	background: var(--primary);
	background: -webkit-linear-gradient(
		to right,
		var(--light),
		var(--primary)
	);
	background: linear-gradient(to right, var(--light), var(--primary));
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	color: black;
	position: relative;
	left: -100%;
	height: 100%;
	width: 200%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.password-container .container.right-panel-active .overlay {
	transform: translateX(50%);
}

.password-container .overlay-panel {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 40px;
	text-align: center;
	top: 0;
	height: 100%;
	width: 50%;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
}

.password-container .overlay-left {
	transform: translateX(-20%);
}

.password-container .container.right-panel-active .overlay-left {
	transform: translateX(0);
}

.password-container .overlay-right {
	right: 0;
	transform: translateX(0);
}

.password-container .container.right-panel-active .overlay-right {
	transform: translateX(20%);
}

.password-container .white-text {
	color: var(--light) !important;
}

@media screen and (max-width: 1200px) {
	.password-container .container {
		width: 85vw;
	}
}

@media screen and (max-width: 1024px) {
	.password-container .container {
		width: 90vw;
	}
}

@media screen and (max-width: 768px) {
	.password-container .container {
		display: none;
	}
	.password-container .small-screen-login {
		display: flex;
		background-color: var(--light);
		border-radius: 10px;
		box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
		position: relative;
		overflow: hidden;
		width: 90vw;
		min-height: 480px;
		height: fit-content;
		padding: 20px auto;
	}
	.password-container form {
		width: 100%;
		padding: 0 25px;
	}
	.password-container .password-link {
		cursor: pointer;
		color: var(--primary);
		transition: all 0.5s ease;
		margin: 30px 0;
	}
	.password-container .password-link:hover {
		color: var(--tertiary);
	}
}

@media screen and (max-width: 480px) {
	.password-container{
		margin-top: 0px;
		padding-top: 50px;
	}
	.password-container .container {
		display: none;
	}
	.password-container .small-screen-login {
		display: flex;
		background-color: var(--light);
		border-radius: 10px;
		box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
		position: relative;
		overflow: hidden;
		width: 90vw;
		min-height: 480px;
		height: fit-content;
		padding: 20px auto;
	}
	.password-container form {
		width: 100%;
		padding: 0 25px;
	}
	.password-container .password-link {
		cursor: pointer;
		color: var(--primary);
		transition: all 0.5s ease;
		margin: 30px 0;
	}
	.password-container .password-link:hover {
		color: var(--tertiary);
	}
}
