.footer {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--primary);
	color: var(--light);
	height: fit-content;
	width: 100%;
	padding-top: 50px;
}

.footer .container {
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: flex-start;
	width: 85vw;
	height: 100%;
}

.footer .container .logo {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 200px;
	height: 200px;
	overflow: hidden;
	border: 2px solid var(--secondary);
	border-radius: 5px;
}

.footer .container .logo img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	transition: all 0.5s ease;
}

.footer .container .logo img:hover {
	transform: scale(1.1);
	cursor: pointer;
}

.footer .container .top{
	position: absolute;
	bottom: 10px;
	right: 10px;
}

.footer .container .top .topIcon {
	height: 25px;
	width: 25px;
	color: var(--light);
	margin-right: 5px;
	cursor: pointer;
	transition: all 0.5s ease;
}

.footer .container .top .topIcon:hover {
	color: var(--secondary);
	transform: scale(1.05);
}

.footer .container .right {
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	width: calc(85vw / 2);
	height: 100%;
}

.footer .container .right .tabs h3 {
	margin-bottom: 20px;
	font-family: var(--regular);
	letter-spacing: 1px;
}

.footer .container .right .tabs ul {
	list-style: none;
}

.footer .container .right .tabs ul li {
	display: flex;
	margin: 20px 0;
	transition: all 0.5s ease;
	text-decoration: none;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.footer .container .icon{
	height: 25px;
	width: 25px;
	color: var(--light);
	margin-right: 5px;
}

.footer .container .right .tabs ul li:hover {
	transform: scale(1.05);
	cursor: pointer;
}

footer {
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 2px solid var(--light);
	margin-top: 50px;
	width: 100%;
	padding: 30px 0;
}

.footer .text,
.footer .text span {
	color: var(--light);
	font-family: var(--regular);
	transition: all 0.5s ease;
	text-align: center;
}

.footer .text span:hover {
	color: var(--secondary);
	cursor: pointer;
	text-align: center;
}

@media screen and (max-width: 1200px) {
	.footer .container {
		width: 90vw;
	}
	.footer .container .logo {
		width: 150px;
		height: 150px;
	}
}

@media screen and (max-width: 1024px) {
	.footer .container {
		width: 85vw;
	}
	.footer .container {
		display: flex;
		flex-direction: column;
		position: relative;
		justify-content: center;
		align-items: center;
		width: 85vw;
	}
	.footer .container .right {
		margin-top: 25px;
		justify-content: space-evenly;
		width: 85vw;
		height: 100%;
	}
	.footer .container .right .tabs {
		width: 40vw;
	}
	.footer .container .icon{
		height: 20px;
		width: 20px;
	}
	.footer .container .logo {
		width: 200px;
		height: 200px;
	}
	
}

@media screen and (max-width: 768px) {
	.footer .container {
		width: 90vw;
	}
	.footer .container {
		display: flex;
		flex-direction: column;
		position: relative;
		justify-content: center;
		align-items: center;
		width: 90vw;
	}
	.footer .container .right {
		margin-top: 20px;
		width: 90vw;
		height: 100%;
	}
	.footer .container .right .tabs {
		width: 45vw;
	}
}

@media screen and (max-width: 480px) {
	.footer .container {
		width: 90vw;
	}
	.footer .container {
		display: flex;
		flex-direction: column;
		position: relative;
		justify-content: center;
		align-items: center;
		width: 90vw;
	}
	.footer .container .logo {
		width: 300px;
		height: 300px;
	}

	.footer .container .right {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 20px;
		width: 90vw;
		height: 100%;
	}
	.footer .container .right .tabs {
		width: 90vw;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 20px;
		padding-bottom: 20px;
	}
	.footer .container .right .tabs ul {
		list-style: none;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.footer .container .right .tabs ul li {
		margin: 10px 0;
	}
	footer {
		margin-top: 30px;
		width: 90vw;
		padding: 20px 0;
	}
}
