.cart-bar {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	width: 450px;
	max-height: 100vh;
	background-color: var(--primary);
	padding: 10px;
	transform: translateX(450px);
}

.cart-bar .main {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 100%;
}

.cart-bar .main .cart-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 20px 10px;
	border-bottom: 1px solid var(--light);
}

.cart-bar .cart-header h2 {
	font-size: 25px;
	font-family: var(--semibold);
	color: var(--light);
	text-transform: uppercase;
}

.cart-bar .cart-header .close-icon {
	width: 25px;
	height: 25px;
	cursor: pointer;
	color: var(--light);
	transition: all 0.5 ease;
}

.cart-bar .cart-header .close-icon:hover {
	transform: scale(1.1);
}

.cart-bar .cart-footer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 20px 10px;
	border-top: 1px solid var(--gray);
}

.cart-bar .cart-footer .price {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.cart-bar .cart-footer p {
	font-size: 12px;
	font-family: var(--regular);
	color: var(--light);
	text-transform: uppercase;
	margin: 10px 0;
}

.cart-bar .cart-footer .price h3 {
	font-size: 25px;
	font-family: var(--semibold);
	color: var(--light);
	text-transform: uppercase;
}

.cart-bar .cart-footer .checkout {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 15px 0;
	background-color: var(--light);
	color: var(--primary);
	font-size: 14px;
	font-family: var(--semibold);
	text-transform: uppercase;
	cursor: pointer;
	transition: all 0.5s ease;
	border-radius: 3px;
	border: 2px solid var(--primary);
}

.cart-bar .cart-footer .checkout:hover {
	background-color: var(--primary);
	color: var(--light);
	border: 2px solid var(--light);
}

.cart-bar .cart-body {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	width: 100%;
}

.cart-bar .cart-body .cart-item {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	margin-top: 20px;
	padding: 10px 20px;
	border-bottom: 1px solid var(--gray);
}

.cart-bar .cart-body .empty {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.cart-bar .cart-body .empty h3 {
	font-size: 20px;
	font-family: var(--semibold);
	color: var(--light);
	text-transform: uppercase;
}

.cart-bar .cart-body .empty h4 {
	font-size: 16px;
	font-family: var(--semibold);
	color: var(--light);
	text-transform: uppercase;
	cursor: pointer;
	transition: all 0.5s ease;
	margin: 20px;
}

.cart-bar .cart-body .empty h4:hover {
	color: var(--tertiary);
}

@media screen and (max-width: 768px) {
	.cart-bar {
		width: 450px;
		transform: translateX(450px);
	}
}

@media screen and (max-width: 480px) {
	.cart-bar {
		width: 100vw;
		transform: translateX(110vw);
	}
	.cart-bar .main {
		padding-bottom: 10px;
	}
	.cart-bar .cart-footer p {
		font-size: 11px;
	}

	.cart-bar .cart-footer .price h3 {
		font-size: 20px;
	}
}
