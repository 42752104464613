.newsletter-screen {
	display: flex;
	position: relative;
	background-color: var(--secondary);
	height: fit-content;
	justify-content: center;
	align-items: center;
	z-index: 1;
	padding-top: 100px;
	padding-bottom: 150px;
}

.newsletter-screen .container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 85vw;
	min-height: 100px;
	height: fit-content;
	overflow: hidden;
}

.newsletter-screen .container p {
	font-size: 30px;
	color: var(--light);
	letter-spacing: 3px;
	font-family: var(--semibold);
	margin: 20px 0 40px 0;
	text-transform: uppercase;
	text-align: center;
}

.newsletter-screen .container .input-container {
	display: flex;
	position: relative;
	justify-content: flex-start;
	align-items: center;
	width: 35vw;
	height: 60px;
	border-radius: 30px;
	background-color: var(--primary);
	overflow: hidden;
}

.newsletter-screen .container .input-container input {
	width: 75%;
	height: 100%;
	border: none;
	font-family: var(--regular);
	font-size: 14px;
	color: var(--primary);
	padding: 0 20px;
}

.newsletter-screen .container .input-container input::placeholder {
	font-family: var(--regular);
	font-size: 14px;
}

.newsletter-screen .container .input-container input:focus {
	border: none;
	outline: none;
}

.newsletter-screen .container .input-container .button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 25%;
	height: 100%;
	border-radius: 30px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	background-color: var(--primary);
	cursor: pointer;
}

.newsletter-screen .container .input-container .button span {
	font-family: var(--semibold);
	font-size: 16px;
	color: var(--light);
	letter-spacing: 2px;
	text-transform: uppercase;
}

@media screen and (max-width: 1200px) {
	.newsletter-screen .container {
		width: 90vw;
	}
	.newsletter-screen .container .input-container {
		width: 50vw;
		height: 60px;
	}

}

@media screen and (max-width: 1024px) {
	.newsletter-screen .container .input-container {
		width: 70vw;
		height: 60px;
	}
}

@media screen and (max-width: 768px) {
	.newsletter-screen .container .input-container {
		width: 80vw;
		height: 60px;
	}
	.newsletter-screen .container p {
		font-size: 20px;
		margin: 20px 0;
	}
	.newsletter-screen .container .input-container .button span {
		font-size: 14px;
		letter-spacing: 0;
	}
}

@media screen and (max-width: 480px) {
	.newsletter-screen .container .input-container {
		width: 90vw;
		height: 50px;
	}
	.newsletter-screen .container p {
		font-size: 18px;
		margin: 20px 0;
	}
	.newsletter-screen .container .input-container .button span {
		font-size: 14px;
		letter-spacing: 0;
	}

}
