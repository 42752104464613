.product-screen {
	display: flex;
	position: relative;
	flex-direction: column;
	background-color: var(--secondary);
	height: fit-content;
	justify-content: center;
	align-items: center;
	margin-top: 100px;
}

.product-screen .back {
	width: 85vw;
	margin: 30px 0;
	font-size: 14px;
	font-family: var(--semibold);
	color: var(--light);
	cursor: pointer;
	text-align: left;
	transition: all 0.5s ease;
}

.product-screen .back:hover {
	color: var(--secondary);
}

.product-screen .main {
	display: flex;
	position: relative;
	justify-content: space-around;
	align-items: center;
	width: 85vw;
	min-height: 50vh;
	height: fit-content;
	overflow: hidden;
	margin: 0 0 50px 0;
}

.product-screen .main .image-side {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 42vw;
	min-height: 50vh;
	height: fit-content;
}

.product-screen .main .image-container {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	width: 35vw;
	height: 35vw;
	overflow: hidden;
	border: 1px solid var(--gray);
	border-radius: 5px;
}

.product-screen .main .image-container img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.product-screen .main .info-side {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 42vw;
	min-height: 50vh;
	height: fit-content;
}

.product-screen .main .info-side h1 {
	font-size: 40px;
	font-family: var(--semibold);
	color: var(--light);
	margin-bottom: 50px;
}

.product-screen .info-side .price,
.product-screen .info-side .color-container,
.product-screen .info-side .description-container {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	height: fit-content;
	margin-bottom: 30px;
}

.product-screen .info-side .price h4,
.product-screen .info-side .color-container h4,
.product-screen .info-side .description-container h4 {
	font-size: 20px;
	font-family: var(--semibold);
	color: var(--primary);
	margin-bottom: 10px;
}

.product-screen .color-container .colors {
	display: flex;
	position: relative;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: fit-content;
}

.product-screen .color-container .colors .color {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	border: 1px solid var(--light);
	border-radius: 5px;
	margin-right: 10px;
	margin-bottom: 10px;
	cursor: pointer;
	transition: all 0.5s ease;
	text-overflow: clip;
	white-space: nowrap;
	overflow: hidden;
}

.product-screen .color-container .colors .color {
	width: 110px;
}

.product-screen .color-container .colors .color:hover {
	background-color: var(--secondary);
	color: var(--background);
	border: 1px solid var(--primary);
	transform: scale(1.1);
}

.product-screen .color-container .colors .color.selected {
	background-color: var(--success);
	color: var(--background);
	border: 2px solid var(--primary);
	font-family: var(--semibold);
}

.product-screen .price h2 {
	font-size: 40px;
	font-family: var(--semibold);
	color: var(--primary);
}

.product-screen .add-to-cart {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	width: 25vw;
	height: 55px;
	background-color: var(--primary);
	color: var(--light);
	font-size: 20px;
	font-family: var(--regular);
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: all 0.5s ease;
	border: 2px solid var(--tertiary);
}

.product-screen .add-to-cart:hover {
	background-color: var(--primary50);
	transform: scale(1.05);
	border: 2px solid var(--primary);
}

@media screen and (max-width: 1200px) {
	.product-screen .back {
		width: 90vw;
		margin: 30px 0;
	}
	.product-screen .main {
		width: 90vw;
	}
}

@media screen and (max-width: 1024px) {
	.product-screen .main {
		width: 90vw;
	}
	.product-screen .main .image-container {
		width: 45vw;
		height: 100%;
	}
	.product-screen .add-to-cart {
		width: 40vw;
		height: 50px;
	}
}

@media screen and (max-width: 768px) {
	.product-screen .main {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 90vw;
		margin: 0 0 40px 0;
	}
	.product-screen .main .image-side {
		width: 90vw;
		margin-bottom: 30px;
	}
	.product-screen .main .image-container {
		width: 80vw;
		height: 100%;
	}
	.product-screen .main .info-side {
		justify-content: center;
		align-items: center;
		width: 90vw;
	}
	.product-screen .add-to-cart {
		width: 90vw;
		height: 50px;
	}
	.product-screen .main .info-side h1 {
		font-size: 30px;
		margin-bottom: 30px;
	}
	.product-screen .price h2 {
		font-size: 30px;
	}
}

@media screen and (max-width: 480px) {
	.product-screen {
		margin-top: 60px;
		border-top: 3px solid var(--tertiary);
	}

	.product-screen .main {
		margin: 0 0 30px 0;
	}
	.product-screen .main .image-side {
		width: 90vw;
		margin-bottom: 30px;
	}
	.product-screen .main .image-container {
		width: 90vw;
		height: 100%;
	}
	.product-screen .main .info-side {
		justify-content: center;
		align-items: center;
		width: 90vw;
	}
	.product-screen .add-to-cart {
		width: 90vw;
		height: 50px;
	}
	.product-screen .main .info-side h1 {
		font-size: 30px;
		margin-bottom: 30px;
	}
	.product-screen .info-side .price h4,
	.product-screen .info-side .color-container h4,
	.product-screen .info-side .description-container h4 {
		font-size: 20px;
		margin-bottom: 8px;
	}
	.product-screen .price h2 {
		font-size: 25px;
	}
}
