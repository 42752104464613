.label-input-component {
	width: 100%;
}
.label-input-component .label {
	font-family: var(--semibold);
	font-size: 14px;
	color: var(--primary);
	margin-left: 5px;
	margin-top: 10px;
	text-align: left;
}

.label-input-component .input-container {
	width: 100%;
	padding: 5px;
	border: 1px solid var(--primary);
	border-radius: 10px;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.label-input-component .input {
	flex: 1;
	padding: 7px 10px;
	font-size: 14px;
	font-family: var(--regular);
	color: var(--primary);
	justify-content: center;
	align-items: center;
	width: 100%;
	border: none;
	border-radius: 10px;
}

.label-input-component .input:focus {
	outline: none;
	background-color: var(--light);
}

.label-input-component .input-text:focus {
	outline: none;
	background-color: var(--light);
}

@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 480px) {
}
